import React from 'react';
import {Route,BrowserRouter,Redirect} from 'react-router-dom';
import Main from './Components/Main';
import logo from './logo.svg';
import './App.css';
import ContactUs from './Components/ContactUs';
import AboutUs from './Components/AboutUs';
import Terms from './Components/Terms';

class App extends React.Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <BrowserRouter>
                <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/home"/>}
                />
                  <Route
                    exact
                    path="/about-us"
                    render={() => <Redirect to="/about-us"/>}
                />
                <Route exact path="/home" component={Main} />
                
                <Route  path="/about-us" component={AboutUs}/>

                <Route  path="/contact-us" component={ContactUs} />
                    
                <Route  path="/temrs"component={Terms} />
      </BrowserRouter>
    )
  }
}

export default App;
