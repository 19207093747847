import React from 'react';
import { NavLink } from "react-router-dom";
import halooBackground from '../img/haloo-background.svg';
import header from '../img/header.png';
import headerLg from '../img/headerLg.png';
import headerMd from '../img/headerMd.png';

class Header extends React.Component{
    render(){
        return(
            <div className="header-component" style={{background:halooBackground}}>
                  <div className="logo-head" >
                        <img src={header} alt="header-pic" className="header-background-XL" />
                        <img src={headerLg} alt="header-pic" className="header-background-LG" />
                        <img src={headerMd} alt="header-pic" className="header-background-MD" />
                  </div>
                  <div className="haloo-logo-head">
                      <img src={halooBackground} alt="hal00-logo" />
                  </div>
                  <div className="link-head">
                  <NavLink to='/home' activeClassName="activeTagHeader"> Home</NavLink>
                   <NavLink to='/about-us'activeClassName="activeTagHeader"> About Us</NavLink>
                   <NavLink to='/contact-us'activeClassName="activeTagHeader"> Contact Us</NavLink>
                   <NavLink to='/temrs'activeClassName="activeTagHeader"> Terms </NavLink>
                  </div>
            </div>
        )
    }
}
export default Header;