import React from "react";
import { NavLink } from "react-router-dom";
import headerWavelg from "../img/headerWavelg.png";
import headerWavesm from "../img/headerWavesm.png";
import girl from "../img/girl.png";
import propertyBottom from "../img/propertyBottom.png";
import propertyBottomSvg from "../img/propertyBottom.svg";
import whyHaloo from "../img/why-haloo.png";
import "./Style.css";
import Footer from "./Footer";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";


class Main extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="main-container">
          <ScrollUpButton />
            <div className="container"  >
              <div className="top-wave">
                <img src={headerWavelg} alt="top-wave" className="top-wave-svg" />
                <img src={headerWavesm} alt="top-wave" className="top-wave-svg-sm" />
              </div>
              <div className="header">
                <NavLink to="/home" activeClassName="activeTag">
                  {" "}
                  Home
                </NavLink>
                <NavLink to="/about-us" activeClassName="activeTag">
                  {" "}
                  About Us
                </NavLink>
                <NavLink to="/contact-us" activeClassName="activeTag">
                  {" "}
                  Contact Us
                </NavLink>
                <NavLink to="/temrs" activeClassName="activeTag">
                  {" "}
                  Terms{" "}
                </NavLink>
                <a
                  href="https://cafebazaar.ir/app/com.nazdika.app"
                  className="cafebazar"
                >
                  Download
                </a>
              </div>

              <div className="main">
                <div className="find-txt">
                  <h2>Find Your Perfect Match</h2>
                  <p>In less that 50 swipes!</p>
                  <p>Wohooooo!</p>
                </div>
               
              </div>
              <div className="copule-svg">
                      {/* <Copule /> */}
                </div>
           <div className="wave-bottom">
             {/* <Wave /> */}
           </div>
            </div>

            <div className="second-part">
              <div className="second-part-container">
                <img src={girl} alt="girl" className="girl-img" />
                <div className="property-txt">
                  <h3>Tired of swiping a million times on Tinder and getting nothing?</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis parturient montes, nascetur
                    ridiculus{" "}
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis parturient montes, nascetur
                    ridiculus{" "}
                  </p>
                </div>
              </div>
              <img
                src={propertyBottom}
                alt="propertyBottom"
                className="propertyBottom"
              />
               <img
                src={propertyBottomSvg}
                alt="propertyBottom"
                className="propertyBottomSvg"
              />
            </div>
 
            <div className="third-part">
              <div className="why-haloo-txt">
                <h3>We care about your privacy</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                  natoque penatibus et magnis dis parturient montes, nascetur
                  ridiculus
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                  natoque penatibus et magnis dis parturient montes, nascetur
                  ridiculus
                </p>
              </div>
              <img
                src={whyHaloo}
                alt="why-haloo-pic"
                className="why-haloo-pic"
              />
            </div>
            <Footer />
      </div>
    );
  }
}

export default Main;
