import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import girlcontactus from "../img/girl-contactus.png";

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: "",
      errors: {},
      fields: {}
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }
  handleChange(event) {
    let fields = this.state.fields;
    fields[event.target.name] = event.target.value;
    this.setState({
      fields
    });
  }
  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["fullName"]) {
      formIsValid = false;
      errors["fullName"] = "Please Write Your Full Name";
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Please Write Your Email Address";
    }
    if (typeof fields["email"] !== "email") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "Please Write Correct Email";
      }
    }
 
    if (!fields["textarea"]) {
      formIsValid = false;
      errors["textarea"] = "Please Write Your Message";
    }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }
  handleSubmit(event) {
    event.preventDefault();
    if (this.validateForm()) {
      let fields = {};
      fields["fullName"] = "";
      fields["email"] = "";
      fields["textarea"] = "";
      this.setState({
        fields: fields,
        success:
          "Your Message was Sent Successfully"
      });
    }
  }
  render() {
    return (
      <div className="contact-us-container">
        <Header />
        <div className="content-contact-us">
          <div className="pic-girl">
            <img src={girlcontactus} alt="pic" />
          </div>
          <div className="txt-and-form">
            <h2>Contact Us</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod
            </p>
            <form onSubmit={this.handleSubmit} className="form">
              
                <input
                  type="text"
                  name="fullName"
                  className="nameInput"
                  placeholder="* Your FullName"
                  value={this.state.fields.fullName}
                  onChange={this.handleChange}
                />
                <div className="errorMsg">{this.state.errors.fullName}</div>
              
            
                <input
                  type="text"
                  name="email"
                  className="emailInput"
                  placeholder="* Your Email"
                  value={this.state.fields.email}
                  onChange={this.handleChange}
                />
                <div className="errorMsg">{this.state.errors.email}</div>
              
              <textarea
                rows="8"
                cols="80"
                name="textarea"
                className="textarea"
                placeholder="* Your Message"
                value={this.state.fields.textarea}
                onChange={this.handleChange}
              />
              <div className="errorMsg">{this.state.errors.textarea}</div>

              <input type="submit" value="submit" className="submitBtn"/>
              <div className="successMsg">{this.state.success}</div>
            </form>
          </div>
        </div>
        <div className="footer-contact-us">
        <Footer />
        </div>
      </div>
    );
  }
}

export default ContactUs;
