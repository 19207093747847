import React from 'react';
import { NavLink } from "react-router-dom";
import logo from '../img/logo.png';

class Footer extends React.Component{
    render(){
        return(
            <footer>
            <div className="footer-container">
            <div className="footer-link">
                   <NavLink to='/home' activeClassName="activeTag"> Home</NavLink>
                    <NavLink to='/about-us'activeClassName="activeTag"> About Us</NavLink>
                    <NavLink to='/contact-us'activeClassName="activeTag"> Contact Us</NavLink>
                    <NavLink to='/temrs'activeClassName="activeTag"> Terms </NavLink>
           </div>
           <div className="logo-footer">
              <img src={logo} alt="logo" />
           </div>
            </div>
         </footer>
        )
    }
}
export default Footer;